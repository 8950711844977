import { useSelector } from 'react-redux';
import { communityTeamsSelector } from '../../../../../redux/selectors/community-teams';
import { fetchAllCommunityTeams } from '../../../../../redux/actions/community-teams';
import { useEffect } from 'react';

const withMetaCommunityLogic = (WrappedComponent) => {
  return ({
    multiSelect,
    onChangeCallback,
    selected,
    axiosInstance,
    dispatch,
    styles,
    ...props
  }) => {
    const teamsData = useSelector(communityTeamsSelector);

    const communityFilterOptions = teamsData?.communityTeams?.results?.map(
      (item) => ({
        ...item,
        label: item?.teamName,
        value: item?.communities
      })
    );

    useEffect(() => {
      const fetchAndSetCommunities = async () => {
        if (
          !teamsData?.communityTeams?.results?.length &&
          !teamsData?.isLoading
        ) {
          dispatch(fetchAllCommunityTeams(axiosInstance, `limit=1000000`));
        }
      };
      fetchAndSetCommunities();
    }, [
      axiosInstance,
      teamsData?.isLoading,
      dispatch,
      teamsData?.communityTeams?.results?.length
    ]);

    return (
      <WrappedComponent
        multiSelect={multiSelect}
        onChangeCallback={onChangeCallback}
        selected={selected}
        communityFilterOptions={communityFilterOptions}
        communitiesLoading={teamsData?.loading}
        styles={styles}
        {...props}
      />
    );
  };
};

export default withMetaCommunityLogic;
