import ErrorHandler from '../../error-handlers/axios/defaultErrorHandler';
import { getHotLeadsActivities } from '../getHotLeadsActivities';
import { getHotLeadsTotalActivities } from '../getHotLeadsTotalActivities';

export const getTotalAssignedActivities = async (axiosInstance, options) => {
  try {
    const data = await getHotLeadsTotalActivities(axiosInstance, {
      ...options,
      params: {
        ...options.params,
        assigned: '1'
      }
    });

    return data;
  } catch (error) {
    ErrorHandler.handle(error);
  }
};

export const getTotalAttendedActivities = async (axiosInstance, options) => {
  try {
    const data = await getHotLeadsTotalActivities(axiosInstance, {
      ...options,
      params: {
        ...options.params,
        attended: '1'
      }
    });

    return data;
  } catch (error) {
    ErrorHandler.handle(error);
  }
};

export const getTotalFollowUpActivities = async (axiosInstance, options) => {
  try {
    const data = await getHotLeadsTotalActivities(axiosInstance, {
      ...options,
      params: {
        ...options.params,
        followUp: '1'
      }
    });

    return data;
  } catch (error) {
    ErrorHandler.handle(error);
  }
};

export const getTotalLostOrDisqualifiedActivities = async (
  axiosInstance,
  options
) => {
  try {
    const data = await getHotLeadsTotalActivities(axiosInstance, {
      ...options,
      params: {
        ...options.params,
        lostOrDisqualified: '1'
      }
    });

    return data;
  } catch (error) {
    ErrorHandler.handle(error);
  }
};

export const getTotalExpiredActivities = async (axiosInstance, options) => {
  try {
    const data = await getHotLeadsTotalActivities(axiosInstance, {
      ...options,
      params: {
        ...options.params,
        expired: '1'
      }
    });

    return data;
  } catch (error) {
    ErrorHandler.handle(error);
  }
};

export const getTotalDealActivities = async (axiosInstance, options) => {
  try {
    const data = await getHotLeadsTotalActivities(axiosInstance, {
      ...options,
      params: {
        ...options.params,
        deal: '1'
      }
    });

    return data;
  } catch (error) {
    ErrorHandler.handle(error);
  }
};

export const getTotalListingAcquiredActivities = async (
  axiosInstance,
  options
) => {
  try {
    const data = await getHotLeadsTotalActivities(axiosInstance, {
      ...options,
      params: {
        ...options.params,
        listing: '1'
      }
    });

    return data;
  } catch (error) {
    ErrorHandler.handle(error);
  }
};


export const getListingAcquiredHotLeads = async (
  axiosInstance,
  options
) => {
  try {
    const data = await getHotLeadsActivities(axiosInstance, {
      ...options,
      params: {
        ...options.params,
        listing: '1'
      }
    });

    return data;
  } catch (error) {
    ErrorHandler.handle(error);
  }
};

export const getDealHotLeads = async (
  axiosInstance,
  options
) => {
  try {
    const data = await getHotLeadsActivities(axiosInstance, {
      ...options,
      params: {
        ...options.params,
        deal: '1'
      }
    });

    return data;
  } catch (error) {
    ErrorHandler.handle(error);
  }
};

export const getExpiredHotLeads = async (axiosInstance, options) => {
  try {
    const data = await getHotLeadsActivities(axiosInstance, {
      ...options,
      params: {
        ...options.params,
        expired: '1'
      }
    });

    return data;
  } catch (error) {
    ErrorHandler.handle(error);
  }
};

export const getAssignedHotLeads = async (axiosInstance, options) => {
  try {
    const data = await getHotLeadsActivities(axiosInstance, {
      ...options,
      params: {
        ...options.params,
        assigned: '1'
      }
    });

    return data;
  } catch (error) {
    ErrorHandler.handle(error);
  }
};

export const getAttendedHotLeads = async (axiosInstance, options) => {
  try {
    const data = await getHotLeadsActivities(axiosInstance, {
      ...options,
      params: {
        ...options.params,
        attended: '1'
      }
    });

    return data;
  } catch (error) {
    ErrorHandler.handle(error);
  }
};

export const getFollowUpHotLeads = async (axiosInstance, options) => {
  try {
    const data = await getHotLeadsActivities(axiosInstance, {
      ...options,
      params: {
        ...options.params,
        followUp: '1'
      }
    });

    return data;
  } catch (error) {
    ErrorHandler.handle(error);
  }
};

export const getLostOrDisqualifiedHotLeads = async (
  axiosInstance,
  options
) => {
  try {
    const data = await getHotLeadsActivities(axiosInstance, {
      ...options,
      params: {
        ...options.params,
        lostOrDisqualified: '1'
      }
    });

    return data;
  } catch (error) {
    ErrorHandler.handle(error);
  }
};