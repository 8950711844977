import { Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { AgentReportContext, HotLeadsTableView, HotLeadTableTabs } from '../../../AgentReportLayout';
import { useContext, useState } from 'react';
import { classNames } from '@react-pdf-viewer/core';
import _ from 'lodash';
import { useHotLeadsQuery } from '../hooks/useHotLeadsQuery';
import Skeleton from 'react-loading-skeleton';
import { Icon, PaginationComponent } from '../../../../../../components/Component';
import StatusBadge from '../../../../../explorer/leads-view/components/StatusPill';
import { STATUS_COLORS } from '../../../../../explorer/leads-view/constants';
import { format, isValid } from 'date-fns';
import { Link } from 'react-router-dom';
import LeadSourceFilter from '../../../../../components/filter-components/LeadSourceFilter';
import SelectAgent from '../../../../../../components/SelectAgent';
import AgentTeamFilter from '../../../../../components/filter-components/AgentTeamFilter';

const HotLeadsTableContainer = () => {
  const { activeTableTab, switchTab, tableView, selectedTeamLeader, setSelectedTeamLeader } = useContext(AgentReportContext);
  const [selectedQuery, setSelectedQuery] = useState(HotLeadTableTabs[0].name);
  const [pageIndex, setPageIndex] = useState(1);
  const [selectedLeadSource, setSelectedLeadSource] = useState(null);
  const [selectedAgent, setSelectedAgent] = useState(null);

  const query = useHotLeadsQuery(selectedQuery, {
    leadSource: selectedLeadSource?.value,
    agents: selectedAgent?.id ?? selectedAgent?._id,
    leader: selectedTeamLeader?.userId,
    page: pageIndex
  });

  return (
    <Card className="p-4">
      <Nav tabs className="d-flex justify-content-between">
        {HotLeadTableTabs.map((tab) => (
          <NavItem key={`hot-lead-tab-${tab.key}`}>
            <NavLink
              style={{ cursor: 'pointer' }}
              className={classNames({
                active: _.isEqual(activeTableTab, tab)
              })}
              onClick={() => {
                switchTab(tab.key);
                setPageIndex(1);
                setSelectedQuery(tab.name);
              }}
            >
              {tab.label}
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      <div className="d-flex justify-content-start w-100 pb-2 mt-4" style={{ gap: '1rem' }}>
        <div className="flex-grow-1 w-50 position-relative">
          <LeadSourceFilter
            stateVar={selectedLeadSource}
            onChange={(option) => {
              setSelectedLeadSource((_) => option);
            }}
            hotOnly
          />
          {selectedLeadSource && (
            <span
              className="fs-6 position-absolute text-danger"
              style={{ top: 7, right: 30 }}
              role="button"
              onClick={() => setSelectedLeadSource(null)}
            >
              <Icon name={'cross'} />
            </span>
          )}
        </div>
        <div className="flex-grow-1 w-50 position-relative">
          <SelectAgent
            isClearable
            notStyled
            selectedAgent={selectedAgent}
            onSelectAgent={(agent) => {
              setSelectedAgent((_) => agent);
            }}
          />
          {selectedAgent && (
            <span
              className="fs-6 position-absolute text-danger"
              style={{ top: 7, right: 30 }}
              role="button"
              onClick={() => setSelectedAgent(null)}
            >
              <Icon name={'cross'} />
            </span>
          )}
        </div>
        {tableView === HotLeadsTableView.Team ? (
          <div className="flex-grow-1 w-50 position-relative">
            <AgentTeamFilter
              stateVar={selectedTeamLeader}
              onChange={(option) => {
                setSelectedAgent(_ => null);
                setSelectedTeamLeader((_) => option);
              }}
            />
            {selectedTeamLeader && (
              <span
                className="fs-6 position-absolute text-danger"
                style={{ top: 7, right: 30 }}
                role="button"
                onClick={() => setSelectedTeamLeader((_) => null)}
              >
                <Icon name={'cross'} />
              </span>
            )}
          </div>
        ) : null}
      </div>
      <TabContent activeTab={activeTableTab.key}>
        {HotLeadTableTabs.map((tab) => (
          <TabPane tabId={tab.key} key={`hot-lead-tab-content-${tab.key}`}>
            {!query.isLoading && !query?.data?.found && !query.isError ? (
              <Card>
                <CardBody className="text-center align-middle">No {tab.name} leads found</CardBody>
              </Card>
            ) : null}

            {query.isLoading && !query.isError ? <HotLeadsTableLoader /> : null}

            {query.isError ? (
              <div
                className="p-2 text-danger d-flex flex-column justify-content-center align-items-center"
              >
                <p>Failed to fetch hot leads.</p>
                <span className="text-primary" role="button" onClick={() => query.refetch()}>
                  Try again
                </span>
              </div>
            ) : null}

            {!query.isLoading && !query.isError && query?.data?.found ? (
              <div>
                <div className="nk-tb-list border rounded">
                  <TableHeader />
                  {query.data?.data?.map((item, idx) => (
                    <div className="nk-tb-item" key={`hot-lead-table-item-${item?.id ?? idx + 1}`}>
                      <div className="nk-tb-col text-start">
                        <Link
                          to={`/user-profile/${
                            item?.user?.id ?? item?.user?._id
                          }/user-profile-setting`}
                        >
                          {`${item?.user?.firstName} ${item?.user?.lastName ?? ''}`}
                        </Link>
                      </div>
                      <div className="nk-tb-col text-center">{item?.user?.phone ?? 'Unknown'}</div>
                      <div className="nk-tb-col text-center">
                        {item?.user?.currentAgent?.user?.first_name ?? 'Unassigned'}
                      </div>
                      <div className="nk-tb-col text-center">
                        <StatusBadge
                          style={{ width: '5rem', marginInline: 'auto' }}
                          status={item?.user?.leadStatus ?? ''}
                          color={STATUS_COLORS[item?.user?.leadStatus] ?? 'primary'}
                        />
                      </div>
                      <div className="nk-tb-col text-center text-nowrap">
                        {!item?.user?.lastActivityIsComplete &&
                        item?.user?.lastActivityDate &&
                        isValid(new Date(item?.user?.lastActivityDate))
                          ? format(new Date(item?.user?.lastActivityDate), 'dd/MM/yyyy hh:mm a')
                          : '-'}
                      </div>
                      <div className="nk-tb-col text-center text-nowrap">
                        {item?.completedDate && isValid(new Date(item?.completedDate))
                          ? format(new Date(item?.completedDate), 'dd/MM/yyyy hh:mm a')
                          : '-'}
                      </div>
                      <div className="nk-tb-col text-end">
                        {item?.user?.leadSourceData?.name ?? 'Unknown'}
                      </div>
                    </div>
                  ))}
                </div>

                <div className="d-flex justify-content-end mt-4">
                  <PaginationComponent
                    currentPage={query?.data?.page ?? pageIndex ?? 1}
                    itemPerPage={query?.data?.itemsPerPage ?? 10}
                    totalItems={query?.data?.found ?? 0}
                    paginate={(page) => {
                      setPageIndex((_) => page);
                    }}
                  />
                </div>
              </div>
            ) : null}
          </TabPane>
        ))}
      </TabContent>
    </Card>
  );
};

const TableHeader = () => (
  <div className="nk-tb-item nk-tb-head">
    <div className="nk-tb-col fw-semibold text-secondary text-start">
      <span>Name</span>
    </div>
    <div className="nk-tb-col fw-semibold text-secondary text-center">
      <span className="text-center">Phone</span>
    </div>
    <div className="nk-tb-col fw-semibold text-secondary text-center">
      <span>Currently Assigned Agent</span>
    </div>
    <div className="nk-tb-col fw-semibold text-secondary text-center">
      <span>Status</span>
    </div>
    <div className="nk-tb-col fw-semibold text-secondary text-center">
      <span>Scheduled At</span>
    </div>
    <div className="nk-tb-col fw-semibold text-secondary text-center">
      <span>Last Activity At</span>
    </div>
    <div className="nk-tb-col fw-semibold text-secondary text-end">
      <span>Lead Source</span>
    </div>
  </div>
);

const HotLeadsTableLoader = ({ rowCount = 5 }) => {
  return (
    <div>
      <div className="nk-tb-list border rounded">
        <TableHeader />
        {Array(rowCount)
          .fill(0)
          .map((_, idx) => (
            <div className="nk-tb-item" key={`hot-lead-table-skeleton-${idx}`}>
              <div className="nk-tb-col">
                <Skeleton />
              </div>
              <div className="nk-tb-col">
                <Skeleton />
              </div>
              <div className="nk-tb-col">
                <Skeleton />
              </div>
              <div className="nk-tb-col">
                <Skeleton />
              </div>
              <div className="nk-tb-col">
                <Skeleton />
              </div>
              <div className="nk-tb-col">
                <Skeleton />
              </div>
              <div className="nk-tb-col">
                <Skeleton />
              </div>
            </div>
          ))}
      </div>
      <div className="d-flex mt-2 justify-content-end">
        <Skeleton width={200} height={30} />
      </div>
    </div>
  );
};
export default HotLeadsTableContainer;
