/* eslint-disable no-unused-vars */
import ExplorerLeadsView from './ExplorerLeadsView';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import useLeadsWithPropCards from './hooks/useLeadsWithPropCards';
import { useSelector } from 'react-redux';
import useFetchQuery from '../../../hooks/useFetch';
import { fetchAgentByUserId } from '../../../api/agents/fetchAgentByUserId';
import { newRoles } from '../../../utils/envConfig';
import useMutationQuery, {
  MutationQueryResult
} from '../../../hooks/useMutationQuery';
import { checkoutExplorerLeads } from '../../../api/property-cards/checkoutExplorerLeads';
import { useQueryClient } from 'react-query';
import { showToast } from '../../../utils/toast/toast';

/**
 * @typedef {Object} Filter
 * @property {string} label - The label of the filter.
 * @property {string} value - The value of the filter (typically a MongoDB ID).
 * @property {Record<string, any>} [otherFields] - Other optional fields.
 */

/**
 * @typedef {Object} QueryState
 * @property {number} page - The current page number.
 * @property {number} limit - Number of items per page.
 * @property {Filter[]} communities - An array of community filter objects.
 * @property {Filter[]} buildings - An array of building IDs (mongodb ids).
 * @property {Filter[]} propertyTypes - An array of property types.
 * @property {string} beds - the number of beds.
 * @property {string} search - Search term, used to find by name, email, or phone number.
 */

/**
 * @typedef {Object} SelectedState
 * @property {Set<string> | undefined} cards - The list of property cards to checkout.
 * @property {Set<string>} leads - The list of leads to checkout.
 */

const ExplorerLeadsContainer = () => {
  const axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const location = useLocation();
  const query = queryString.parse(location.search);

  const authUser = useSelector((state) => state.user.loggedInUser);
  const isAdmin =
    authUser &&
    (authUser.roles?.includes('MasterAdmin') ||
      authUser.roles?.includes('Admin'));

  /**
   * State hook for managing query parameters.
   * @type {[QueryState, import("react").Dispatch<import("react").SetStateAction<QueryState>>]}
   */
  const [queryState, setQueryState] = useState({
    page: query?.page ? Number(query?.page) : 1,
    limit: query?.limit ? Number(query?.limit) : 20,
    communities: query?.communities ? JSON.parse(query?.communities) : [],
    buildings: query?.buildings ? JSON.parse(query?.buildings) : [],
    propertyTypes: query?.propertyTypes ? JSON.parse(query?.propertyTypes) : [],
    beds: null,
    newLeads: query?.newLeads ? query?.newLeads : false
  });

  /**
   * State hook for managing query parameters.
   * @type {[SelectedState, import("react").Dispatch<import("react").SetStateAction<SelectedState>>]}
   */
  const [selectedItems, setSelectedItems] = useState({
    leads: new Set(),
    cards: new Set()
  });

  useEffect(() => {
    const newQueryString = queryString.stringify(
      {
        ...queryState,
        propertyTypes: queryState?.propertyTypes?.length
          ? JSON.stringify(
              queryState?.propertyTypes?.map((propType) => ({
                value: propType?.value,
                label: propType?.label
              }))
            )
          : [],
        buildings: queryState?.buildings?.length
          ? JSON.stringify(
              queryState?.buildings?.map((building) => ({
                value: building?.value,
                label: building?.label,
                communityId: building?.communityId
              }))
            )
          : [],
        // communities: queryState?.communities?.length
        //   ? JSON.stringify(
        //       queryState?.communities?.map((community) => ({
        //         value: community?.value,
        //         label: community?.label
        //       }))
        //     )
        //   : []
        communities: queryState?.communities?.length
          ? JSON.stringify(queryState?.communities) : []
      },
      { skipNull: true, skipEmptyString: true }
    );
    window.history.pushState(
      null,
      '',
      `${location.pathname}?${newQueryString}`
    );
  }, [queryState, location.pathname]);

  const isAnyFilterSelected =
    queryState?.propertyTypes?.length > 0 ||
    queryState?.buildings?.length > 0 ||
    queryState?.communities?.length > 0;

  const { data, isError, error, isFetching, status } = useLeadsWithPropCards(
    axios,
    queryState,
    {
      condition: isAdmin ? isAnyFilterSelected : true
    }
  );

  const agentData = useFetchQuery(axios, {
    fetchFn: fetchAgentByUserId,
    customArgs: [(authUser?.id ?? authUser?._id)?.toString()],
    queryCondition: authUser && authUser?.roles?.includes(newRoles.Agent),
    queryKeyName: 'authAgentData',
    queryKeyDeps: [authUser]
  });

  const mutationQuery = useMutationQuery(
    (payload) => checkoutExplorerLeads(axios, payload),
    {
      mutationKey: 'checkoutLeads',
      onSettled: () => {
        queryClient.invalidateQueries('leadsWithPropCards');
        queryClient.invalidateQueries(['authAgentData', authUser]);
        // clear selections after mutation resolves (whatever the result is)
        setSelectedItems((_) => ({
          leads: new Set(),
          cards: new Set()
        }));
      },
      onError: (error) => {
        console.error(error);
        showToast(error?.message ?? 'Assignment failed', 'error');
      },
      onSuccess: (data) => {
        const { leadsCount, propertyCardsCount } = data;
        if (leadsCount && leadsCount >= 0) {
          showToast(
            `Successfully assigned ${leadsCount} lead(s) and ${
              propertyCardsCount ?? 0
            } property card(s).`,
            'success'
          );
        } else {
          showToast('Successfully assigned leads.', 'success');
        }
      }
    }
  );

  const handlePropertyTypesFilterChange = (selectedOptions) => {
    if (selectedOptions.some((option) => option.value === 'all')) {
      setQueryState((prev) => ({ ...prev, propertyTypes: [], page: 1 }));
    } else {
      setQueryState((prev) => ({
        ...prev,
        propertyTypes: selectedOptions,
        page: 1
      }));
    }
  };

  const handleCommunitiesFilterChange = (selectedOptions) => {
    if (selectedOptions.some((option) => option.value === 'all')) {
      setQueryState((prev) => ({
        ...prev,
        communities: [],
        buildings: [],
        page: 1
      }));
    } else {
      setQueryState((prev) => ({
        ...prev,
        communities: selectedOptions,
        page: 1
      }));
    }
  };

  const handleBuildingFilterChange = (selectedOptions) => {
    if (selectedOptions.some((option) => option.value === 'all')) {
      setQueryState((prev) => ({ ...prev, buildings: [], page: 1 }));
    } else {
      // const communitiesToAdd = selectedOptions?.map((option) => ({
      //   label: option?.community?.community,
      //   value: option?.community?.id ?? option?.community?._id,
      //   ...option?.community
      // }));
      // let newCommunities = queryState?.communities?.concat(communitiesToAdd);
      // newCommunities = _.uniqBy(newCommunities, 'value');

      setQueryState((prev) => ({
        ...prev,
        buildings: selectedOptions,
        // communities: newCommunities,
        page: 1
      }));
    }
  };

  /**
   * @typedef {Object} Callbacks
   * @property {(Filter[]) => void} communityCallback - Callback function for community filter changes.
   * @property {(Filter[]) => void} buildingCallback - Callback function for building filter changes.
   * @property {(Filter[]) => void} propertyTypeCallback - Callback function for property type filter changes.
   * @property {MutationQueryResult} checkoutLeads - Mutation object to use when assigning leads.
   */
  const callBacks = {
    communityCallback: handleCommunitiesFilterChange,
    buildingCallback: handleBuildingFilterChange,
    propertyTypeCallback: handlePropertyTypesFilterChange,
    checkoutLeads: mutationQuery
  };

  return (
    <ExplorerLeadsView
      data={data}
      isFetching={isFetching}
      status={status}
      isError={isError}
      errorVal={error}
      queryState={queryState}
      setQueryState={setQueryState}
      selectedItems={selectedItems}
      setSelectedItems={setSelectedItems}
      axiosInstance={axios}
      dispatch={dispatch}
      callBacks={callBacks}
      agentData={agentData}
    />
  );
};

export default ExplorerLeadsContainer;
