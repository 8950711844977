import ErrorHandler from "../error-handlers/axios/defaultErrorHandler"

export const getHotLeadsActivities = async (axiosInstance, options) => {
  try {
    const res = await axiosInstance.get(`/activities/hot-leads/filter`, options);
    console.log(options);
    console.log(res?.data);
    return res?.data;
  } catch (error) {
    ErrorHandler.handle(error);
  }
}