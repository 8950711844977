import { useContext } from "react";
import useAxiosPrivate from "../../../../../../hooks/useAxiosPrivate";
import { AgentReportContext } from "../../../AgentReportLayout";
import { getAssignedHotLeads, getAttendedHotLeads, getDealHotLeads, getExpiredHotLeads, getFollowUpHotLeads, getListingAcquiredHotLeads, getLostOrDisqualifiedHotLeads } from "../../../../../../api/activities/use-cases/hotLeadsQueries";
import { useQuery } from "react-query";
import { timeAsMilliseconds } from "../../../../../../utils/Utils";

export const HotLeadsQueries = {
  Assigned: 'assigned',
  Attended: 'attended',
  FollowUp: 'followUp',
  LostOrDisqualified: 'lostOrDisqualified',
  ListingAcquired: 'listingAcquired',
  Deal: 'deal',
  Expired: 'expired'
};

export const useHotLeadsQuery = (queryName, { leadSource = null, agents = null, leader = null, page = 1 }, { refetchIntervalMs } = {}) => {
  const axios = useAxiosPrivate();
  const { globalQueryParams } = useContext(AgentReportContext);

  let queryParams = {  ...globalQueryParams, page };

  if (leadSource) {
    queryParams = { ...queryParams, leadSource };
  }
  
  if (agents) {
    queryParams = { ...queryParams, agents };
  }

  if (leader) {
    queryParams = { ...queryParams, leader };
  }

  const query = {
    queryKey: null,
    queryFn: () => {}
  };

  switch (queryName) {
    case HotLeadsQueries.Assigned:
      query.queryKey = ['assignedHotLeads', queryParams, leadSource];
      query.queryFn = () => getAssignedHotLeads(axios, { params: queryParams });
      break;
    case HotLeadsQueries.Attended:
      query.queryKey = ['attendedHotLeads', queryParams];
      query.queryFn = () => getAttendedHotLeads(axios, { params: queryParams });
      break;
    case HotLeadsQueries.FollowUp:
      query.queryKey = ['followUpHotLeads', queryParams];
      query.queryFn = () => getFollowUpHotLeads(axios, { params: queryParams });
      break;
    case HotLeadsQueries.LostOrDisqualified:
      query.queryKey = ['lostOrDisqualifiedHotLeads', queryParams];
      query.queryFn = () => getLostOrDisqualifiedHotLeads(axios, { params: queryParams });
      break;
    case HotLeadsQueries.Deal:
      query.queryKey = ['dealHotLeads', queryParams];
      query.queryFn = () => getDealHotLeads(axios, { params: queryParams });
      break;
    case HotLeadsQueries.ListingAcquired:
      query.queryKey = ['listingAcquiredHotLeads', queryParams];
      query.queryFn = () => getListingAcquiredHotLeads(axios, { params: queryParams });
      break;
    case HotLeadsQueries.Expired:
      query.queryKey = ['expiredHotLeads', queryParams];
      query.queryFn = () => getExpiredHotLeads(axios, { params: queryParams });
      break;
    default:
      break;
  }

  const result = useQuery(
    query.queryKey,
    query.queryFn,
    { refetchInterval: refetchIntervalMs ?? timeAsMilliseconds({ minutes: 10 }), refetchOnWindowFocus: false, enabled: !!queryName }
  );

  return result;
};
